
import { InjectionToken } from '@angular/core';

import { Performance as NodePerformance } from 'perf_hooks';

export const PERFORMANCE = new InjectionToken<Performance | NodePerformance>('api:performance');
export const REFERRER = new InjectionToken<string>('app:referrer');

export enum Loggers {
  Renderer = 'renderer',
  Cache = 'cache',
  HttpClient = 'httpClient',
  Performance = 'performance',
}
